import { TemplateArgument } from './template-argument.model';
import { formatDate } from '@angular/common';

export function replaceArgumentsForString(
    unprocessedString: string,
    templateArguments: TemplateArgument[],
    replaceWhenEmpty: boolean = true
): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        if (typeof Worker !== 'undefined') {
            // Create a new
            //const worker = new Worker(new URL('./parts.worker', import.meta.url));
            //worker.onmessage = ({data}) => resolve(data);
            //worker.postMessage({unprocessedString: unprocessedString, templateArguments: templateArguments});
            resolve(replaceArgumentsForStringWorker(unprocessedString, templateArguments, replaceWhenEmpty));
        } else {
            resolve(replaceArgumentsForStringWorker(unprocessedString, templateArguments, replaceWhenEmpty));
        }
    });
}

function valueToDate(value: any) {
    if (!isNaN(+value)) {
        return new Date(+value);
    } else {
        return new Date(value);
    }
}

export function replaceArgumentsForStringWorker(
    unprocessedString: string,
    templateArguments: TemplateArgument[],
    replaceWhenEmpty: boolean = true
): string {
    let processedString = unprocessedString;
    if (!!templateArguments) {
        if (!!processedString) {
            templateArguments.forEach(templateArgument => {
                const regExp = new RegExp(
                    `\\!?\\{\\{${
                        templateArgument.parameter ? templateArgument.parameter.name : templateArgument.name
                    }(\\.[A-z^0-9]*)*( ?\\| ?[A-z]*)?\\}\\}`,
                    'g'
                );
                const matchingGroups = processedString.match(regExp);
                processedString = replace(matchingGroups, processedString, templateArgument, replaceWhenEmpty);
            });
            const replaceEmptyRegExp = new RegExp(`\\!\\{\\{([A-z^0-9\\.]*)*( ?\\| ?[A-z]*)?\\}\\}`, 'g');
            const replaceEmptyMatchingGroups = processedString.match(replaceEmptyRegExp);
            if (replaceEmptyMatchingGroups) {
                for (const replaceEmptyMatchingGroup of replaceEmptyMatchingGroups) {
                    processedString = processedString.replace(replaceEmptyMatchingGroup, '');
                }
            }
        }
    }
    return processedString;
}

export function containsPlaceholders(unprocessedString: string) {
    const replaceEmptyRegExp = new RegExp(`\\{\\{([A-z^0-9\\.]*)*( ?\\| ?[A-z]*)?\\}\\}`, 'g');
    const replaceEmptyMatchingGroups = unprocessedString.match(replaceEmptyRegExp);
    return !!replaceEmptyMatchingGroups;
}
function replace(
    matchingGroups: RegExpMatchArray | null,
    processedString: string,
    templateArgument: TemplateArgument,
    replaceWhenEmpty: boolean = true
): string {
    if (matchingGroups) {
        for (const matchingGroup of matchingGroups) {
            let value = templateArgument.calculatedValue;
            if (!value && replaceWhenEmpty) {
                value = '';
            }
            if (typeof value === 'object') {
                const pipeParts = matchingGroup.replace('{{', '').replace('!{{', '').replace('}}', '').split('|');
                const matchingGroupParts = pipeParts[0].trim().split('.');
                matchingGroupParts.shift();
                for (const matchingGroupPart of matchingGroupParts) {
                    if (value != null) {
                        value = value[matchingGroupPart];
                    }
                }

                if (value != null) {
                    if (pipeParts.length > 1 && !!pipeParts[1] && pipeParts[1].trim() === 'dateformat') {
                        processedString = processedString.replace(
                            `${matchingGroup}`,
                            formatDate(valueToDate(value), 'dd/MM/yyyy', 'en_US')
                        );
                    } else if (pipeParts.length > 1 && !!pipeParts[1] && pipeParts[1].trim() === 'datetimeformat') {
                        processedString = processedString.replace(
                            `${matchingGroup}`,
                            formatDate(valueToDate(value), 'dd/MM/yyyy HH:mm:ss', 'en_US')
                        );
                    } else if (pipeParts.length > 1 && !!pipeParts[1] && pipeParts[1].trim() === 'json') {
                        processedString = processedString.replace(`${matchingGroup}`, JSON.stringify(value, null, 4));
                    } else {
                        processedString = processedString.replace(`${matchingGroup}`, value);
                    }
                }
            } else {
                const pipeParts = matchingGroup.replace('{{', '').replace('}}', '').split('|');
                if (value != null) {
                    if (pipeParts.length > 1 && !!pipeParts[1] && pipeParts[1].trim() === 'dateformat') {
                        processedString = processedString.replace(
                            `${matchingGroup}`,
                            formatDate(valueToDate(value), 'dd/MM/yyyy', 'en_US')
                        );
                    } else if (pipeParts.length > 1 && !!pipeParts[1] && pipeParts[1].trim() === 'datetimeformat') {
                        processedString = processedString.replace(
                            `${matchingGroup}`,
                            formatDate(valueToDate(value), 'dd/MM/yyyy HH:mm:ss', 'en_US')
                        );
                    } else {
                        processedString = processedString.replace(`${matchingGroup}`, value);
                    }
                }
            }
        }
    }
    return processedString;
}
