<div #grid class="subcontainer part-content" [id]="'id-' + part.id" (click)="onClick()">
    <ng-container *ngFor="let part of partDetail.parts; trackBy: identifyPart">
        <ng-container *ngIf="!part.hidden">
            <horizontallist-part
                class="part"
                [attr.id]="'part-' + part.id"
                [templateVersion]="templateVersion"
                [application]="application"
                [partId]="part.id"
                [host]="host"
                [parentFormGroup]="parentFormGroup"
                (executeAction)="executeAction.emit($event)">
            </horizontallist-part>
        </ng-container>
    </ng-container>
</div>
