<div
    [id]="'template-' + template.templateDto.id"
    *ngIf="parentFormGroup && template && template.templateDto"
    class="part-container"
    [attr.style]="generateTemplateStyle()">
    <ng-container *ngFor="let part of template.parts; trackBy: identifyPart">
        <ng-container *ngIf="!part.hidden">
            <part
                [attr.id]="'part-' + part.id"
                [attr.data-name]="part?.detail.code"
                [templateVersion]="template"
                [templateIdentifier]="templateIdentifier"
                [application]="application"
                [partId]="part.id"
                [host]="host"
                [parentFormGroup]="parentFormGroup"
                [style.display]="this.part.displayNone ? 'none' : this.part.displayBlock ? 'block' : null"
                (executeAction)="this.executeAction.emit($event)">
            </part>
        </ng-container>
    </ng-container>
</div>
