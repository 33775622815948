import { ActionExecutionInvocationArgumentDto } from './action-execution-invocation-argument.dto';
import { TemplateStyleDto } from './template.style.dto';
import { TemplateDto } from './template.dto.interface';

export interface CreateActionExecutionDto {
    actionId?: string;
    actionIds?: string[];
    trigger: string;
    triggerType: string;
    templateVersion: {
        id: string;
        style: TemplateStyleDto;
        parts: any[];
        templateDto: TemplateDto;
    };
    applicationId: string;
    companyId: string;
    arguments?: ActionExecutionInvocationArgumentDto[];
    currentUrl: { path: string; domain: string };
    browser: { clipboard?: string; language: string; geolocation?: string; online: boolean; userAgent: string };
    formResults?: any;
}
