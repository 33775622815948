import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { frontofficeEnvironment } from '@shared/environment';
import { KeycloakService } from 'keycloak-angular';
import { catchError, take } from 'rxjs/operators';
import { AppState } from '../store/app.state';
import { select, Store } from '@ngrx/store';
import { selectApplication } from '../store/application/application.selectors';
import { DOCUMENT } from '@angular/common';
import { ApplicationDto } from '../dto/application.dto.interface';

export const InterceptorSkipHeader = 'X-Skip-Error-Interceptor';
export const InterceptorSkip404Header = 'X-Skip-404-Error-Interceptor';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
    application$ = this.store.pipe(select(selectApplication));

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private httpClient: HttpClient,
        private keycloak: KeycloakService,
        private store: Store<AppState>
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has(InterceptorSkipHeader)) {
            return next.handle(req);
        } else {
            const nextObs = next.handle(req);
            if (nextObs) {
                return nextObs.pipe(catchError(error => this.handleErrorsObservable(error, req, next)));
            } else {
            }
        }
    }

    handleErrorsObservable(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (error.status === 404 && !req.headers.has(InterceptorSkip404Header)) {
            return this.handleResourceNotFoundErrors(req);
        } else if (error.status === 401) {
            this.application$.pipe(take(1)).subscribe(application => {
                const redirectUrl = this.getRedirectUrl(application);
                if (application.loginTemplateId) {
                    const loginUrl = this.keycloak.getKeycloakInstance().createLoginUrl({
                        redirectUri: redirectUrl,
                        idpHint: application.forceIdp,
                    });
                    window.location.href = loginUrl;
                } else {
                    this.keycloak.login({
                        redirectUri: redirectUrl,
                        idpHint: application.forceIdp,
                    });
                }
            });
            return EMPTY;
        } else if (error.status === 403) {
            return this.handleForbiddenError(error);
        } else if (error.status === 509) {
            return this.handleUsageExceededError();
        } else if (error.status === 500) {
            return this.handleUnknownError(req, error);
        } else if (error.status !== 200 && error.status !== 404) {
            return this.handleUnknownError(req, error);
        } else {
            return EMPTY;
        }
    }

    getRedirectUrl(application: ApplicationDto) {
        if (application.loginRedirectPath && application.loginRedirectHost) {
            if (location.host.startsWith('localhost')) {
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${location.host}&template=${application.loginRedirectPath}&params=${btoa(location.search)}`
                );
            } else {
                const hostNameParts = location.hostname.split('.');
                const subdomain = hostNameParts.shift();
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${application.loginRedirectHost + '.' + hostNameParts.join('.')}&template=${
                        application.loginRedirectPath
                    }&params=${btoa(location.search)}`
                );
            }
        } else {
            return (
                frontofficeEnvironment.redirectUrl +
                `?host=${location.protocol}//${location.host}&template=${location.pathname}&params=${btoa(location.search)}`
            );
        }
    }

    handleUsageExceededError(): Observable<void> {
        this.application$.pipe(take(1)).subscribe(application => {
            this.router.navigateByUrl('status/usage-exceeded', { replaceUrl: true });
        });
        return EMPTY;
    }

    handleResourceNotFoundErrors(req: HttpRequest<any>): Observable<void> {
        this.application$.pipe(take(1)).subscribe(application => {
            if (
                !req.url.endsWith(application.notFoundUrl) &&
                !req.url.endsWith(application.unAuthorizedUrl) &&
                !req.url.endsWith(application.errorUrl)
            ) {
                this.router.navigateByUrl(application.notFoundUrl, { replaceUrl: true });
            } else if (req.url.endsWith(application.notFoundUrl)) {
                this.router.navigateByUrl('/status/nocodex-default-not-found', { replaceUrl: true });
            } else if (req.url.endsWith(application.unAuthorizedUrl)) {
                this.router.navigateByUrl('/status/nocodex-default-forbidden', { replaceUrl: true });
            } else if (req.url.endsWith(application.errorUrl)) {
                this.router.navigateByUrl('/status/nocodex-default-error', { replaceUrl: true });
            }
        });
        return EMPTY;
    }

    handleUnknownError(req: HttpRequest<any>, error: HttpErrorResponse): any {
        this.application$.pipe(take(1)).subscribe(application => {
            this.router.navigateByUrl(application.errorUrl, { replaceUrl: true });
        });
        return EMPTY;
    }

    handleForbiddenError(error: HttpErrorResponse): any {
        this.application$.pipe(take(1)).subscribe(application => {
            this.router.navigateByUrl(application.unAuthorizedUrl, { replaceUrl: true });
        });
        return EMPTY;
    }

    getHeader(error: HttpErrorResponse, headerName: string): string {
        if (error && error.headers) {
            return error.headers.get(headerName);
        } else {
            return null;
        }
    }
}

export const HttpErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
};
