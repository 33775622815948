import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ApplicationDto } from 'apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { ComponentFront } from '../../../../interface/component.front';
import { TemplateFacade } from '../../../../../../../../../frontoffice/data-access/template/src/lib/facade/template.facade';
import { getPartDetailComponents } from '../../../../part-module';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'part',
    templateUrl: './part.component.html',
    styleUrls: ['./part.component.scss'],
})
export class PartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input()
    partId: string;

    @Input()
    templateIdentifier: string;

    @Input()
    templateVersion: TemplateVersion;

    @Input()
    application: ApplicationDto;

    @Input()
    parentFormGroup: FormGroup;

    @Input()
    host: string;

    @Input()
    currentScreenType: number;

    @ViewChild('partDetail', { read: ViewContainerRef }) partDetail: ViewContainerRef;

    @Output()
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }> = new EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>();

    part$: Observable<Part>;

    subscriptions: Subscription = new Subscription();

    private componentRef: ComponentRef<ComponentFront>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private templateFacade: TemplateFacade
    ) {}

    ngOnInit(): void {
        this.part$ = this.templateFacade.fetchPart(this.partId, this.templateIdentifier);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateComponentWithTemplateVersion();
    }

    ngAfterViewInit() {
        this.initComponent();
    }

    ngOnDestroy() {
        this.templateFacade.removePartInstanceFromStore(this.templateIdentifier + '-' + this.partId);
        this.subscriptions.unsubscribe();
    }

    initComponent() {
        this.subscriptions.add(
            this.part$.subscribe(part => {
                if (this.componentRef && this.componentRef.instance) {
                    // update existing
                    this.updateComponent(part);
                } else {
                    // create new one
                    this.createComponent(part);
                    this.updateComponent(part);
                }
            })
        );
    }

    createComponent(part: Part) {
        const partDetailComponentType: Type<ComponentFront> | undefined = getPartDetailComponents()?.get(part.detail.partType);
        if (partDetailComponentType) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(partDetailComponentType!);
            //this.partDetail.clear();
            const componentRef = this.partDetail.createComponent<ComponentFront>(componentFactory);
            this.componentRef = componentRef;
            this.componentRef.instance.application = this.application;
            this.componentRef.instance.executeAction = this.executeAction;
            this.componentRef.instance.parentFormGroup = this.parentFormGroup;
            if ('host' in this.componentRef.instance) {
                // @ts-ignore
                this.componentRef.instance['host'] = this.host;
            }
            if ('parentFormGroup' in this.componentRef.instance) {
                // @ts-ignore
                this.componentRef.instance['parentFormGroup'] = this.parentFormGroup;
            }
        } else {
            //TODO create a "could not find part of this type" component
        }
    }

    updateComponentWithTemplateVersion() {
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.templateVersion = this.templateVersion;
            this.componentRef.instance.changeDetectorRef.detectChanges();
            this.componentRef.instance.ngOnChanges({});
        }
    }

    updateComponent(part: Part) {
        this.componentRef.instance.partDetail = part.detail;
        this.componentRef.instance.part = part;
        this.componentRef.instance.partStyle = part.style;
        this.componentRef.instance.templateVersion = this.templateVersion;
        if ('host' in this.componentRef.instance) {
            // @ts-ignore
            this.componentRef.instance['host'] = this.host;
        }
        this.componentRef.instance.changeDetectorRef.detectChanges();
        this.componentRef.instance.ngOnChanges({});
    }
}
