import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { getPartDetailComponents } from '../../../../../part-module';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { Observable, Subscription } from 'rxjs';
import { TemplateFacade } from 'libs/frontoffice/data-access/template/src/lib/facade/template.facade';

@Component({
    selector: 'accordion-part',
    templateUrl: './part.component.html',
    styleUrls: ['./part.component.scss'],
})
export class PartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input()
    partId: string;

    @Input()
    templateVersion: TemplateVersion;

    @Input()
    application: ApplicationDto;

    @Input()
    partContainer: Part;

    @Input()
    host: string;

    @Input()
    parentFormGroup: FormGroup;

    @HostBinding('style.width') width: string;
    @HostBinding('style.height') height: string;
    @HostBinding('style.position') position: string;
    @HostBinding('style.z-index') zIndex: string;

    @ViewChild('partDetail', { read: ViewContainerRef }) partDetail: ViewContainerRef;

    @Output()
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }> = new EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>();

    private componentRef: ComponentRef<ComponentFront>;

    private part$: Observable<Part>;
    subscriptions: Subscription = new Subscription();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private templateFacade: TemplateFacade
    ) {}

    ngOnInit(): void {
        this.part$ = this.templateFacade.fetchPart(this.partId, this.templateVersion.instanceIdentifier);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateComponentWithTemplateVersion();
    }

    ngAfterViewInit() {
        this.initComponent();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    initComponent() {
        this.subscriptions.add(
            this.part$.subscribe(part => {
                if (this.componentRef && this.componentRef.instance) {
                    // update existing
                    this.updateComponent(part);
                } else {
                    // create new one
                    this.createComponent(part);
                    this.updateComponent(part);
                }
                this.initializePartPositioning(part);
            })
        );
    }

    initializePartPositioning(part: Part): void {
        if (part.sizeY && part.sizeYUnit !== 'fit-content') {
            this.height = Part.getHeightStyle(part);
        }
        this.position = Part.getPositionStyle(part);
        // Temporary until we get layers.
        if (part.fixedPosition) {
            this.zIndex = '1000';
        }
    }

    createComponent(part: Part) {
        const partDetailComponentType: Type<ComponentFront> | undefined = getPartDetailComponents()?.get(part.detail.partType);
        if (partDetailComponentType) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(partDetailComponentType!);
            //this.partDetail.clear();
            const componentRef = this.partDetail.createComponent<ComponentFront>(componentFactory);
            this.componentRef = componentRef;
            this.componentRef.instance.application = this.application;
            this.componentRef.instance.executeAction = this.executeAction;
            this.componentRef.instance.parentFormGroup = this.parentFormGroup;
            if ('host' in this.componentRef.instance) {
                // @ts-ignore
                this.componentRef.instance['host'] = this.host;
            }
            if ('parentFormGroup' in this.componentRef.instance) {
                // @ts-ignore
                this.componentRef.instance['parentFormGroup'] = this.parentFormGroup;
            }
        } else {
            //TODO create a "could not find part of this type" component
        }
    }

    updateComponentWithTemplateVersion() {
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.templateVersion = this.templateVersion;
            this.componentRef.instance.changeDetectorRef.detectChanges();
            this.componentRef.instance.ngOnChanges({});
        }
    }

    updateComponent(part: Part) {
        this.componentRef.instance.partDetail = part.detail;
        this.componentRef.instance.part = part;
        this.componentRef.instance.partStyle = part.style;

        if (part.sizeX || part.sizeXUnit) {
            this.width = Part.getWidthStyle(part, false, this.partContainer?.style?.grid);
        }
        if (part.sizeY || part.sizeYUnit) {
            this.height = Part.getHeightStyle(part, false, this.partContainer?.style?.grid);
        }
        this.componentRef.instance.templateVersion = this.templateVersion;
        this.componentRef.instance.changeDetectorRef.detectChanges();
        this.componentRef.instance.ngOnChanges({});
    }
}
