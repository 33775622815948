import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TemplateVersion } from '@frontoffice/data-access/template';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, debounceTime, filter, Observable, of, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { PartActionLink } from '../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { ApplicationDto } from '../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { TemplateFacade } from '../../../../../../../../../frontoffice/data-access/template/src/lib/facade/template.facade';

@Component({
    selector: 'app-template-front',
    templateUrl: './template-front.component.html',
    styleUrls: ['./template-front.component.scss'],
})
export class TemplateFrontComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    host = '';

    @Input()
    templateId: string;

    @Input()
    templateVersion: TemplateVersion | undefined;

    @Input()
    passArgumentToBackend: boolean;

    @Input()
    arguments: TemplateArgument[];

    @Input()
    partId: string;

    @Input()
    parentFormGroup: FormGroup;

    @Input()
    formGroupId: string;

    @Input()
    application: ApplicationDto;

    @Input()
    identifier: string;

    @Input()
    languageCode: string | null;

    @Output()
    templateResult: EventEmitter<{ id: string; value: string; name: string }> = new EventEmitter<{
        id: string;
        value: string;
        name: string;
    }>();

    @Output()
    templateLoaded: EventEmitter<TemplateVersion> = new EventEmitter<TemplateVersion>();

    templateInitializedId: string | null;

    templateVersionToCompare: TemplateVersion;

    @Output()
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateVersion?: TemplateVersion;
        executionResultPartId?: string;
    }> = new EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateVersion?: TemplateVersion;
        executionResultPartId?: string;
    }>();

    template$: Observable<TemplateVersion>;

    private readonly subscriptions = new Subscription();

    argumentsPerSelectorId = new Map<string, string>();

    currentPath: string;

    updateTemplate$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private templateFacade: TemplateFacade
    ) {}

    ngOnInit(): void {
        this.updateArgumentsPerSelectorId();
        this.currentPath = window.location.pathname;
        this.template$ = this.templateFacade
            .fetchTemplateById(this.templateId, this.arguments, this.identifier, this.host, this.languageCode)
            .pipe(
                tap(templateVersion => {
                    this.templateInitializedId = templateVersion.templateDto.id;
                })
            );

        this.subscriptions.add(
            this.router.events
                .pipe(filter(e => e instanceof NavigationEnd))
                .pipe(
                    debounceTime(50),
                    switchMap((event, index) => {
                        return this.templateFacade.fetchBaseTemplateResponse(this.templateId).pipe(take(1));
                    }),
                    filter(
                        templateVersionResponse =>
                            !!templateVersionResponse &&
                            !!templateVersionResponse.onEntryActions &&
                            templateVersionResponse.onEntryActions.length > 0
                    ),
                    tap(templateVersionResponse => {
                        return this.templateFacade.executeOnEnterAction(templateVersionResponse, this.host);
                    })
                )
                .subscribe()
        );
        this.subscriptions.add(
            this.updateTemplate$
                .pipe(
                    debounceTime(20),
                    tap(() => {
                        if (this.hasNewValues()) {
                            this.templateFacade.fetchTemplateById(this.templateId, this.arguments, this.identifier, this.host);
                            this.currentPath = window.location.pathname;
                        }
                    })
                )
                .subscribe()
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateTemplate$.next(1);
    }

    hasNewValues() {
        if (this.currentPath !== window.location.pathname) {
            return true;
        }
        for (let i = 0; i < this.arguments.length; i++) {
            const argument = this.arguments[i];
            if (argument.selectorId) {
                if (this.argumentsPerSelectorId.has(argument.selectorId)) {
                    if (this.argumentsPerSelectorId.get(argument.selectorId) !== argument.calculatedValue) {
                        this.updateArgumentsPerSelectorId();
                        return true;
                    }
                } else {
                    this.updateArgumentsPerSelectorId();
                    return true;
                }
            }
        }
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.templateFacade.removeTemplateInstanceFromStore(this.identifier);
    }

    updateArgumentsPerSelectorId() {
        this.arguments.forEach(argument => {
            if (argument.selectorId) {
                this.argumentsPerSelectorId.set(argument.selectorId, argument.calculatedValue);
            }
        });
    }

    onExecuteAction($event: {
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateInstanceIdentifier?: string;
        executionResultPartId?: string;
    }): void {
        this.subscriptions.add(
            this.template$.pipe(take(1)).subscribe(template => {
                if (!$event.templateInstanceIdentifier) {
                    $event.executionResultPartId = this.identifier;
                    $event.templateInstanceIdentifier = this.identifier;
                }
                this.executeAction.emit($event);
            })
        );
    }
}
