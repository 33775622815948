<ng-container *ngIf="{ template: template$ | async, application: application$ | async } as observables">
    <codex-progress *ngIf="(loadingBusys$ | async) === true"></codex-progress>
    <app-template
        *ngIf="observables.template?.templateDto"
        [template]="observables.template"
        [templateIdentifier]="observables.template.instanceIdentifier"
        [application]="observables.application"
        [host]="host"
        [parentFormGroup]="parentFormGroup"
        (executeAction)="onExecuteAction($event)"></app-template>

    <ng-container *ngIf="showDeveloperTools && observables.application && observables.template">
        <app-developer-controls
            [host]="host"
            [applicationId]="observables.application?.applicationId"
            [templateId]="observables.template?.templateDto?.id"></app-developer-controls>
    </ng-container>
</ng-container>
<app-not-found *ngIf="showDefaultNotFound"></app-not-found>
<app-error *ngIf="showDefaultError"></app-error>
<app-forbidden *ngIf="showDefaultForbidden"></app-forbidden>
<app-usage-exceeded *ngIf="showDefaultUsageExceeded"></app-usage-exceeded>
