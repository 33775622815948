<app-template-front
    *ngIf="partDetail.templateId && this.processedArguments"
    [identifier]="'id-' + part.instanceIdentifier"
    [partId]="part.id"
    [formGroupId]="partDetail.formGroupId"
    [templateVersion]="partDetail.templateVersion"
    [languageCode]="templateVersion.templateDto?.languageCode"
    [templateId]="partDetail.templateId"
    [arguments]="this.processedArguments"
    [passArgumentToBackend]="partDetail.passArgumentToBackend"
    [host]="host"
    [application]="application"
    [parentFormGroup]="parentFormGroup"
    (executeAction)="this.executeAction.emit($event)"
    (templateLoaded)="onTemplateLoaded($event)"
    (templateResult)="onTemplateResult($event)"></app-template-front>
