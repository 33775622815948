<div class="part-container" *ngIf="partFormGroup">
    <ng-container *ngFor="let part of template.parts; trackBy: identifyPart">
        <part
            *ngIf="!part.hidden && partFormGroup"
            [attr.id]="'part-' + part.id"
            [partId]="part.id"
            [templateIdentifier]="identifier"
            [host]="host"
            [templateVersion]="template"
            [application]="application"
            [parentFormGroup]="partFormGroup"
            (executeAction)="this.executeAction.emit($event)">
        </part>
    </ng-container>
</div>
